import React, { useState, useEffect } from 'react';
import './../../styles/collection/CollectionView.css';
import { FaSearch, FaEye, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import CollectionData from './CollectionData';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import TransactionDetailsModal from './TransactionDetailsModal';

const COLORS = ['#00C49F', '#FF8042'];

const CollectionView = () => {
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [transactionsData, setTransactionsData] = useState(() => {
    const savedTransactionsData = localStorage.getItem('transactionsData');
    return savedTransactionsData ? JSON.parse(savedTransactionsData) : [
      {
        loanId: "L-001",
        transactions: [
          {
            type: "EDI",
            instalmentNo: 1,
            status: "Success",
            outstandingPrincipal: "₹90,000",
            principal: "₹10,000",
            interest: "₹1,000",
            instalment: "₹11,000",
            statusUpdateTimestamp: "Jun 20, 2023 10:00 AM",
            paymentId: "123456",
            debitUtr: "BANK123",
            finalSettlementUtr: "SBINR520240817123456"
          }
        ]
      }
    ];
  });

  const [profiles, setProfiles] = useState(() => {
    const savedProfiles = localStorage.getItem('profiles');
    return savedProfiles ? JSON.parse(savedProfiles) : [
      { customerName: 'Mahesh', customerId: '123456', loanId: 'L-001', status: 'Registered', umrn: 'UMRN123456', loanType: 'Home Loan', ifscCode: 'UTI1221',
        amount: "₹90,000", disbursalDate: "Jun 20, 2023", dueDate: "Jul 20, 2023", status: "Not Repaid", collectedAmount: 10000, totalAmount: 90000 },
      { customerName: 'Ravi', customerId: '234567', loanId: 'L-002', status: 'Pending', umrn: 'NA', loanType: 'Car Loan', ifscCode: 'UTI1221',
        amount: "₹90,000", disbursalDate: "Jun 20, 2023", dueDate: "Jul 20, 2023", status: "Not Repaid", collectedAmount: 10000, totalAmount: 90000 },
      { customerName: 'Vinod', customerId: '345678', loanId: 'L-003', status: 'Failed', umrn: 'NA', loanType: 'Home Loan', ifscCode: 'UTI12219',
        amount: "₹90,000", disbursalDate: "Jun 20, 2023", dueDate: "Jul 20, 2023", status: "Not Repaid", collectedAmount: 10000, totalAmount: 90000 },
      { customerName: 'Rohit', customerId: '456789', loanId: 'L-004', status: 'Registered', umrn: 'UMRN456789', loanType: 'Car Loan', ifscCode: 'UTI1228',
        amount: "₹90,000", disbursalDate: "Jun 20, 2023", dueDate: "Jul 20, 2023", status: "Not Repaid", collectedAmount: 10000, totalAmount: 90000 },
    ];
  });

  const [collectionsData, setCollectionsData] = useState([]);

  useEffect(() => {
    const filteredCollections = profiles.filter(profile =>
      transactionsData.some(transaction => transaction.loanId === profile.loanId)
    ).map(profile => ({
      customerId: profile.customerId,
      loanId: profile.loanId,
      debitSuccess: Math.floor(Math.random() * 100) // Placeholder logic for debit success
    }));

    setCollectionsData(filteredCollections);
  }, [profiles, transactionsData]);
  
    // Store `profiles` in local storage whenever it changes
    useEffect(() => {
      localStorage.setItem('profiles', JSON.stringify(profiles));
    }, [profiles]);

  const handleViewLoan = (loanId) => {
    const profile = profiles.filter(x => x.loanId === loanId);
    const transactionData = transactionsData.filter(x => x.loanId == loanId);
    let loanDetails = {
      transactions: transactionData[0].transactions,
      loanId: profile[0].loanId,
      amount: profile[0].amount,
      disbursalDate: profile[0].disbursalDate,
      dueDate: profile[0].dueDate,
      status: profile[0].status,
      collectedAmount: profile[0].collectedAmount,
      totalAmount: profile[0].totalAmount
    };
    setSelectedLoan(loanDetails);
  };

  const handleRequestPayment = (loanId) => {
    handleViewLoan(loanId);
    // setSelectedLoan(loanDetails);
  };

  const handleViewTransaction = (transaction) => {
    setSelectedTransaction(transaction);
  };

  const handleCloseTransactionModal = () => {
    setSelectedTransaction(null);
  };

  const renderLoanCollectionPieChart = (loan) => {
    
    const remainingAmount = loan.totalAmount - loan.collectedAmount;
    const data = [
      { name: 'Collected', value: loan.collectedAmount },
      { name: 'Remaining', value: remainingAmount }
    ];

    const CustomTooltip = ({ active, payload }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p>{`${payload[0].name}: ₹${payload[0].value.toLocaleString()}`}</p>
          </div>
        );
      }
      return null;
    };

    return (
      <div className="pie-chart-container">
        <ResponsiveContainer width="100%" height={220}>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={100}
              innerRadius={30}
              fill="#8884d8"
              dataKey="value"
              label={({ name, percent }) => `${(percent * 100).toFixed(0)}%`}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <div className="collections-container">
      {!selectedLoan && (
        <>
          <div className="search-bars">
            <div className="search-input-container">
              <FaSearch className="search-icon" />
              <input type="text" className="search-input" placeholder="Search by Customer ID" />
            </div>
            <div className="search-input-container">
              <FaSearch className="search-icon" />
              <input type="text" className="search-input" placeholder="Search by Loan ID" />
            </div>
            <Link
              to="/bulk-debit"
              className="register-button no-underline"
            >
              Upload Amortization Data
            </Link>
          </div>
        </>
      )}
      
      {!selectedLoan ? (
        <CollectionData collections={collectionsData} onViewLoan={handleViewLoan} onRequestPayment={handleRequestPayment} />
      ) : (
        <div className="loan-details-container">
          <button className="close-button" onClick={() => setSelectedLoan(null)}>✖</button>          
          <h2>View Loan Details</h2>
          <div className="loan-details">
            <div className="loan-info-container">
              <div className="loan-info">
                <p><strong>Loan ID:</strong> {selectedLoan.loanId}</p>
                <p><strong>Loan Amount:</strong> {selectedLoan.amount}</p>
                <p><strong>Loan Disbursal Date:</strong> {selectedLoan.disbursalDate}</p>
                <p><strong>Loan Due Date:</strong> {selectedLoan.dueDate}</p>
                <p><strong>Loan Repayment Status:</strong> {selectedLoan.status}</p>
              </div>
              {renderLoanCollectionPieChart(selectedLoan)}
            </div>
            <div className="transaction-header">
              <h3>Repayment Transactions</h3>
            </div>
              <table className="repayment-table">
                <thead>
                  <tr>
                    <th>Instalment #</th>
                    <th>Payment Status</th>
                    <th>Outstanding (₹)</th>
                    <th>Principal (₹)</th>
                    <th>Interest (₹)</th>
                    <th>Instalment Total (₹)</th>
                    <th>Last Updated</th>
                    <th>View</th>
                  </tr>
              </thead>
              <tbody>
                {selectedLoan.transactions.map((txn, index) => (
                  <tr key={index}>
                    <td>{txn.instalmentNo}</td>
                    <td>
                      <span className={`status-badge ${txn.status.toLowerCase()}`}>{txn.status}</span>
                    </td>
                    <td>{txn.outstandingPrincipal}</td>
                    <td>{txn.principal}</td>
                    <td>{txn.interest}</td>
                    <td>{txn.instalment}</td>
                    <td>{txn.statusUpdateTimestamp}</td>
                    <td>
                      <button 
                        className="view-button"
                        onClick={() => handleViewTransaction(txn)}
                      >
                        <FaEye />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {selectedTransaction && (
        <TransactionDetailsModal
          transaction={selectedTransaction}
          onClose={handleCloseTransactionModal}
        />
      )}
    </div>
  );
};

export default CollectionView;
