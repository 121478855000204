import React, { useState } from 'react';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import './../../styles/customer_engagement/CustomerEngagement.css';

const CustomerEngagement = () => {
  const [activeSection, setActiveSection] = useState('overview');

  const metrics = [
    { title: 'CTA Click Rate', value: '75%', icon: '📈' },
    { title: 'Response Rate', value: '82%', icon: '📊' },
    { title: 'Collection Rate', value: '68%', icon: '💰' }
  ];

  const channels = [
    { title: 'WhatsApp Business', icon: '💬', description: 'Optimize collections via predefined workflows and automated message sequences. Engage customers through their preferred messaging platform.' },
    { title: 'SMS Integration', icon: '📱', description: 'Create and manage SMS campaigns with scheduled notifications and personalized message templates for improved engagement.' },
    { title: 'Email Campaigns', icon: '📧', description: 'Automated email communication with tracking capabilities and customizable templates for different customer segments.' }
  ];

  const tools = [
    { title: 'Digital Collection', icon: '💻', action: 'Manage' },
    { title: 'Telecalling', icon: '📞', action: 'Configure' },
    { title: 'Field Collection', icon: '🏃', action: 'Assign' },
    { title: 'Payment Gateway', icon: '💳', action: 'Setup' }
  ];

  return (
    <Container fluid className="customer-engagement-container">
      <div className="dashboard-header">
        <h2 className="module-title">Customer Engagement Dashboard</h2>
        <div className="quick-actions">
        </div>
      </div>

      <div className="dashboard-content">
        {/* Metrics Overview Section */}
        <div className="section-wrapper">
          <div className="horizontal-scroll">
          </div>
        </div>

        {/* Communication Channels Section */}
        <div className="section-wrapper">
          <h3 className="section-title">Communication Channels</h3>
          <div className="channels-row">
            {channels.map((channel, index) => (
              <Card className="channel-card" key={index}>
                <Card.Body>
                  <div className="channel-icon">{channel.icon}</div>
                  <div className="channel-content">
                    <h4 className="channel-title">{channel.title}</h4>
                    <p className="channel-description">{channel.description}</p>
                  </div>
                  <Button className="configure-button">Configure</Button>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>

        {/* Collection Tools Section */}
        <div className="section-wrapper">
          <div className="horizontal-scroll">

          </div>
        </div>

        {/* Quick Analytics Section */}
        <div className="section-wrapper">
          <h3 className="section-title">Quick Analytics</h3>
          <div className="analytics-row">
            <Card className="analytics-card">
              <Card.Body>
                <div className="analytics-header">
                  <h4>Engagement Trends</h4>
                  <Button variant="primary" className="view-report-btn">
                    View Full Report
                  </Button>
                </div>
                <div className="chart-container">
                  {/* Chart component would go here */}
                  <p>Interactive chart showing engagement metrics over time</p>
                </div>
              </Card.Body>
            </Card>
            <Card className="summary-card">
              <Card.Body>
                <h4>Summary</h4>
                <ul className="summary-list">
                  <li>Total Campaigns: 24</li>
                  <li>Active Workflows: 12</li>
                  <li>Monthly Engagement: 78%</li>
                </ul>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CustomerEngagement;
