import React from 'react';
import RegistrationView from './../../components/registration/RegistrationView';

function Registration() {
  return (
    <div className="Registration">
      <RegistrationView />
    </div>
  );
}

export default Registration;
