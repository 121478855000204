import React, { useState, useEffect } from 'react';
import './../../styles/profile/ProfileView.css';
import { Link } from 'react-router-dom';
import DocumentGrid from './DocumentGrid';
import { FaEye } from 'react-icons/fa';

const ProfileView = () => {
  const [active, setActive] = useState('registrations');

  const [registrations, setRegistrations]  = useState(() => {
    const savedProfiles = localStorage.getItem('profiles');
    return savedProfiles ? JSON.parse(savedProfiles) : [
      { customerName: 'Mahesh', customerId: '123456', loanId: 'L-001', status: 'Registered', umrn: 'UMRN123456', loanType: 'Home Loan', ifscCode: 'UTI1221',
        amount: "₹90,000", disbursalDate: "Jun 20, 2023", dueDate: "Jul 20, 2023", status: "Not Repaid", collectedAmount: 10000, totalAmount: 90000 }
    ];
  });

  // Store `profiles` in local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('profiles', JSON.stringify(registrations));
  }, [registrations]);

  const transactions = [
    {
      instalmentNo: 1,
      status: "Success",
      outstandingPrincipal: "₹90,000",
      principal: "₹10,000",
      interest: "₹1,000",
      instalment: "₹11,000",
      statusUpdateTimestamp: "Nov 1, 2023 10:00 AM",
      paymentTransactionId: "TXN0001",
      debitUtr: "UTR001"
    },
    {
      instalmentNo: 2,
      status: "Success",
      outstandingPrincipal: "₹80,000",
      principal: "₹10,000",
      interest: "₹1,000",
      instalment: "₹11,000",
      statusUpdateTimestamp: "Nov 10, 2023 12:30 PM",
      paymentTransactionId: "TXN0002",
      debitUtr: "UTR002"
    },
    {
      instalmentNo: 3,
      status: "Failure",
      outstandingPrincipal: "₹80,000",
      principal: "₹10,000",
      interest: "₹1,000",
      instalment: "₹11,000",
      statusUpdateTimestamp: "Nov 20, 2023 3:15 PM",
      paymentTransactionId: "TXN0003",
      debitUtr: "UTR003"
    },
    {
      instalmentNo: 4,
      status: "Pending",
      outstandingPrincipal: "₹80,000",
      principal: "₹10,000",
      interest: "₹1,000",
      instalment: "₹11,000",
      statusUpdateTimestamp: "Nov 25, 2023 5:45 PM",
      paymentTransactionId: "TXN0004",
      debitUtr: "UTR004"
    },
    {
      instalmentNo: 5,
      status: "Success",
      outstandingPrincipal: "₹70,000",
      principal: "₹10,000",
      interest: "₹900",
      instalment: "₹10,900",
      statusUpdateTimestamp: "Dec 1, 2023 10:00 AM",
      paymentTransactionId: "TXN0005",
      debitUtr: "UTR005"
    },
    {
      instalmentNo: 6,
      status: "Success",
      outstandingPrincipal: "₹60,000",
      principal: "₹10,000",
      interest: "₹900",
      instalment: "₹10,900",
      statusUpdateTimestamp: "Dec 10, 2023 1:00 PM",
      paymentTransactionId: "TXN0006",
      debitUtr: "UTR006"
    },
    {
      instalmentNo: 7,
      status: "Failure",
      outstandingPrincipal: "₹60,000",
      principal: "₹10,000",
      interest: "₹900",
      instalment: "₹10,900",
      statusUpdateTimestamp: "Dec 20, 2023 11:45 AM",
      paymentTransactionId: "TXN0007",
      debitUtr: "UTR007"
    },
    {
      instalmentNo: 8,
      status: "Pending",
      outstandingPrincipal: "₹60,000",
      principal: "₹10,000",
      interest: "₹900",
      instalment: "₹10,900",
      statusUpdateTimestamp: "Dec 25, 2023 4:00 PM",
      paymentTransactionId: "TXN0008",
      debitUtr: "UTR008"
    },
    {
      instalmentNo: 9,
      status: "Success",
      outstandingPrincipal: "₹50,000",
      principal: "₹10,000",
      interest: "₹800",
      instalment: "₹10,800",
      statusUpdateTimestamp: "Jan 1, 2024 9:30 AM",
      paymentTransactionId: "TXN0009",
      debitUtr: "UTR009"
    },
    {
      instalmentNo: 10,
      status: "Success",
      outstandingPrincipal: "₹40,000",
      principal: "₹10,000",
      interest: "₹800",
      instalment: "₹10,800",
      statusUpdateTimestamp: "Jan 10, 2024 2:15 PM",
      paymentTransactionId: "TXN0010",
      debitUtr: "UTR010"
    }
  ];
  
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedRegistration, setSelectedRegistration] = useState(null);
  const recordsPerPage = 4;

  const [filterStatus, setFilterStatus] = useState({
    All: true,
    Registered: true,
    Pending: true,
    Failed: true,
    Processing: true,
  });

  const filteredRegistrations = registrations.filter(registration =>
    filterStatus.All || filterStatus[registration.status]
  );

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRegistrations = filteredRegistrations.slice(indexOfFirstRecord, indexOfLastRecord);

  const totalPages = Math.ceil(filteredRegistrations.length / recordsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleCancel = () => {
    const updatedRegistrations = [...registrations];
    updatedRegistrations[selectedIndex].status = 'Cancelled';
    setRegistrations(updatedRegistrations);
    setShowModal(false);
  };

  const openCancelModal = (index) => {
    setSelectedIndex(index);
    setShowModal(true);
  };

  const handleResend = (index) => {
    console.log(`Resend initiated for Customer ID: ${registrations[index].customerId}`);
  };

  const handleFilterChange = (status) => {
    if (status === 'All') {
      const newStatus = !filterStatus.All;
      setFilterStatus({
        All: newStatus,
        Registered: newStatus,
        Pending: newStatus,
        Failed: newStatus,
        Processing: newStatus,
      });
    } else {
      const updatedFilterStatus = {
        ...filterStatus,
        [status]: !filterStatus[status],
      };
      const allSelected = Object.keys(updatedFilterStatus)
        .filter(key => key !== 'All')
        .every(key => updatedFilterStatus[key]);
      
      updatedFilterStatus.All = allSelected;
      setFilterStatus(updatedFilterStatus);
    }
    setCurrentPage(1);
  };

  const handleViewRegistration = (registration) => {
    setSelectedRegistration(registration);
  };

  const handleCloseDetails = () => {
    setSelectedRegistration(null);
  };

  return (
    <div className="main-content">
      {!selectedRegistration && (
        <div className="header">
          <h2>Profile</h2>
          <div className="header-buttons">
            <Link
              to="/bulk-register"
              className="register-button no-underline"
              onClick={() => setActive('bulk-register')}
            >
              Upload Customer Profiles
            </Link>
          </div>
        </div>
      )}

      {selectedRegistration ? (
        <div className="loan-details-container">
          <button className="close-button" onClick={handleCloseDetails}>✖</button>
          <h2>User Details</h2>
          <div className="loan-info">
            <p><strong>Customer ID:</strong> {selectedRegistration.customerId}</p>
            <p><strong>Customer Name:</strong> {selectedRegistration.customerName}</p>
            <p><strong>Loan ID:</strong> {selectedRegistration.loanId}</p>
            <p><strong>Status:</strong> {selectedRegistration.status}</p>
            <p><strong>UMRN:</strong> {selectedRegistration.umrn}</p>
            <p><strong>Account Number:</strong> 5010006003</p>
            <p><strong>IFSC Code:</strong> {selectedRegistration.ifscCode}</p>
            <p><strong>Age:</strong> 36</p>
            <p><strong>Gender:</strong> Male</p>
            <p><strong>Phone No:</strong> 6268650076</p>
            <p><strong>Email ID:</strong> mahesh201@gmail.com</p>
            <p><strong>Loan Type:</strong> {selectedRegistration.loanType}</p>
          </div>
          <div className="document-section">
            <h3>Documents</h3>
            <DocumentGrid customerId={selectedRegistration.customerId} />
          </div>
        </div>
      ) : (
        <>
          <table className="registration-table">
            <thead>
              <tr>
                <th>Customer ID</th>
                <th>Customer Name</th>
                <th>Loan ID</th>
                <th>UMRN</th>
                <th>View Details</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {currentRegistrations.map((registration, index) => (
                <tr key={index}>
                  <td>{registration.customerId}</td>
                  <td>{registration.customerName}</td>
                  <td>{registration.loanId}</td>
                  <td>{registration.umrn}</td>
                  <td>
                  <button 
                  className="view-link"
                  onClick={() => handleViewRegistration(registration)}
                >
                  <FaEye className="view-icon" /> View
                </button>
                  </td>
                  {/* <td>
                    {registration.status === 'Processing' ? (
                      <button
                        className="resend-button"
                        onClick={() => handleResend(index)}
                      >
                        Resend
                      </button>
                    ) : (
                      <button
                        className="cancel-button"
                        onClick={() => openCancelModal(index)}
                        disabled={registration.status === 'Cancelled' || registration.status === 'Failed'}
                      >
                        Cancel
                      </button>
                    )}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination-controls">
            <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
            <span>Page <strong>{currentPage}</strong> of {totalPages}</span>
            <button onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
          </div>
        </>
      )}
    
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Confirm Cancellation</h3>
            <p>Are you sure you want to cancel the registration?</p>
            <div className="modal-buttons">
              <button className="confirm-button" onClick={handleCancel}>Yes, Cancel</button>
              <button className="cancel-modal-button" onClick={() => setShowModal(false)}>No, Go Back</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileView;
