import React, { useState } from 'react';
import { FaEye } from 'react-icons/fa';
import 'react-datepicker/dist/react-datepicker.css';
import './../../styles/collection/CollectionView.css';

const CollectionData = ({ collections, onViewLoan , onRequestPayment}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 5;
  const totalPages = Math.ceil(collections.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCollections = collections.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination function
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="collections-table-container">
      <table className="collections-table">
        <thead>
          <tr>
            <th>Customer ID</th>
            <th>Loan ID</th>
            <th>Debit Success Percentage</th>
            <th>View More Details</th>
            <th>Request Payment</th>
          </tr>
        </thead>
        <tbody>
          {currentCollections.map((collection, index) => (
            <tr key={index}>
              <td>{collection.customerId}</td>
              <td>{collection.loanId}</td>
              <td>
                <div className="progress-bar-container">
                  <div className="progress-bar" style={{ width: `${collection.debitSuccess}%` }}></div>
                </div>
                {collection.debitSuccess}%
              </td>
              <td>
                <button
                  className="view-link"
                  onClick={() => onViewLoan(collection.loanId)}
                >
                  <FaEye className="view-icon" /> View
                </button>
              </td>
              <td>
              <button
                className="request-link"
                onClick={() => onRequestPayment(collection.loanId)}
              >
                Request Payment
              </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
            onClick={() => paginate(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CollectionData;
