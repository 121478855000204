import React from 'react';
import BulkCollectionPage from '../../components/collection/BulkCollectionPage';

function BulkDebitPage() {
  return (
    <div className="Collection">
      <BulkCollectionPage />
    </div>
  );
}

export default BulkDebitPage;
