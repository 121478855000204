import React from 'react';
import RegistrationPage from './../../components/registration/RegistrationPage';

function BulkRegisterPage() {
  return (
    <div className="Registration">
      <RegistrationPage />
    </div>
  );
}

export default BulkRegisterPage;
