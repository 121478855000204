import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// pages
import LandingPage from "./containers/landing";
import Login from "./containers/login";
import Dashboard_Main from "./containers/dashboard_main";
import NotfoundPage from "./containers/login/notfound";
import Test from './containers/test';
import { AuthProvider } from "./context/AuthContext";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/login" component={Login} />
        <Route path="/dashboard" component={Dashboard_Main} />
        <Route path="/collections" component={Dashboard_Main} />
        <Route path="/registrations" component={Dashboard_Main} />
        <Route path="/profile" component={Dashboard_Main} />
        <Route path="/analytics" component={Dashboard_Main} />
        <Route path="/test" component={Test} />
        <Route component={NotfoundPage} />
      </Switch>
    </Router>
  );
}

export default App;
