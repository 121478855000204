import React from 'react';
import RegistrationPage from './../../components/registration/RegistrationPage';

function Test() {
  return (
    <div className="Test">
      <RegistrationPage />
    </div>
  );
}

export default Test;
