// src/components/HeroSection.js
import React from 'react';
import './../../styles/landing/HeroSection.css'; // Link the CSS file
import PopupForm from './PopupForm';

const HeroSection = () => {

  const [isPopupVisible, setIsPopupVisible] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);

  const handleButtonClick =() => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {  
  setIsPopupVisible(false);
  };

  const handleFormSubmit = () => {
    setShowToast(true);
    setIsPopupVisible(false);
    setTimeout(() => {
      setShowToast(false);
    }, 3500);
  };

  React.useEffect(() => {
    if (showToast) {
      const toastElement = document.querySelector('.toast-message');
      if (toastElement) {
        toastElement.classList.add('show');
      }
    } else {
      const toastElement = document.querySelector('.toast-message');
      if (toastElement) {
        toastElement.classList.remove('show');
      }
    }
  }, [showToast]);

  return (
    <section className="hero-section">
       <div className="hero-content">
        <h1 className="hero-title">One Stop Platform for</h1>
        <div className="animated-words">
          <span className="word">Seamless Collections</span>
          <span className="word">Smarter Insights</span>
          <span className="word">Zero Delays</span>
        </div>
        <div className="hero-buttons">
          <button className="hero-button primary-button" onClick = {handleButtonClick}> Request Meeting </button>
        </div>
      </div>
      {isPopupVisible && <PopupForm onClose={handleClosePopup} onSubmit={handleFormSubmit} />}
      {showToast && (
         <div className={`toast-message ${showToast ? 'show' : ''}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="green"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-check-circle"
          >
            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
            <polyline points="22 4 12 14.01 9 11.01" />
          </svg>
          <p>Your response has been recorded successfully !</p>
        </div>
      )}
    </section>
  );
};


export default HeroSection;
