import React, { useState, useEffect } from 'react';
import { Select, Card, Row, Col, Statistic, Button } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DownloadOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const { Option } = Select;

const BounceAnalytics = () => {
  const [timeFrame, setTimeFrame] = useState('daily');
  const [data, setData] = useState([]);

  // Mock data - replace with actual API call
  useEffect(() => {
    const mockData = [
      { date: '2024-01-01', bounceRate: 15, totalTransactions: 10000, bounces: 1500 },
      { date: '2024-01-02', bounceRate: 12, totalTransactions: 12000, bounces: 1440 },
      { date: '2024-01-03', bounceRate: 18, totalTransactions: 9000, bounces: 1620 },
      { date: '2024-01-04', bounceRate: 10, totalTransactions: 11000, bounces: 1100 },
      { date: '2024-01-05', bounceRate: 13, totalTransactions: 13000, bounces: 1690 },
    ];
    setData(mockData);
  }, [timeFrame]);

  const currentBounceRate = data.length > 0 ? data[data.length - 1].bounceRate : 0;
  const previousBounceRate = data.length > 1 ? data[data.length - 2].bounceRate : 0;
  const bounceRateChange = currentBounceRate - previousBounceRate;

  const handleTimeFrameChange = (value) => {
    setTimeFrame(value);
  };

  const handleDownload = () => {
    const csvContent = [
      ['Date', 'Bounce Rate', 'Total Transactions', 'Bounces'],
      ...data.map(item => [
        item.date,
        item.bounceRate,
        item.totalTransactions,
        item.bounces
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `bounce-analytics-${timeFrame}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="bounce-analytics">
      <div className="analytics-header">
        <h2>Bounce Analysis</h2>
        <div className="header-controls">
          <Select 
            defaultValue="daily" 
            style={{ width: 120, marginRight: 16 }} 
            onChange={handleTimeFrameChange}
          >
            <Option value="daily">Daily</Option>
            <Option value="weekly">Weekly</Option>
            <Option value="monthly">Monthly</Option>
          </Select>
          <Button 
            type="primary" 
            icon={<DownloadOutlined />} 
            onClick={handleDownload}
          >
            Export Data
          </Button>
        </div>
      </div>

      <Row gutter={16} className="stats-cards">
        <Col span={8}>
          <Card>
            <Statistic
              title="Current Bounce Rate"
              value={currentBounceRate}
              precision={2}
              valueStyle={{ color: bounceRateChange <= 0 ? '#3f8600' : '#cf1322' }}
              prefix={bounceRateChange <= 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
              suffix="%"
            />
            <div className="change-indicator">
              {Math.abs(bounceRateChange).toFixed(2)}% vs previous period
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Total Transactions"
              value={data.length > 0 ? data[data.length - 1].totalTransactions : 0}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Total Bounces"
              value={data.length > 0 ? data[data.length - 1].bounces : 0}
              valueStyle={{ color: '#cf1322' }}
            />
          </Card>
        </Col>
      </Row>

      <Card className="chart-card">
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line 
              type="monotone" 
              dataKey="bounceRate" 
              stroke="#ff4d4f" 
              name="Bounce Rate (%)"
            />
          </LineChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
};

export default BounceAnalytics;
