import React from 'react';
import NavigationSidebar from './NavigationSidebar';
import TopNavigationBar from './TopNavigationBar';
import './../../styles/dashboard_main/DashboardLayout.css';

const DashboardLayout = ({ children }) => {
  return (
    <div className="dashboard-layout">
      {/* Top Navigation Bar */}
      <TopNavigationBar />

      {/* Sidebar and Main Content Container */}
      <div className="main-container">
        {/* Fixed Sidebar */}
        <NavigationSidebar />

        {/* Main Content Area */}
        <div className="main-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
