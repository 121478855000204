import React from 'react';
import './../../styles/dashboard/OverviewCard.css';

const OverviewCard = ({ title, value, change, timeFrame, chartData }) => (
  <div className="card">
    <h3>{title}</h3>
    <h2>{value}</h2>
    <p>{timeFrame} <span className="change">{change}</span></p>
    <div className="chart">
      <Chart data={chartData} />
    </div>
  </div>
);

const Chart = ({ data }) => (
  <div className="chart-bar">
    {data.map((value, index) => (
      <div
        key={index}
        className="bar"
        style={{ height: `${value * 5}px` }}
      ></div>
    ))}
  </div>
);

export default OverviewCard;
