import React from 'react';
import ProfileView from './../../components/profile/ProfileView';

function Registration() {
  return (
    <div className="Registration">
      <ProfileView />
    </div>
  );
}

export default Registration;
