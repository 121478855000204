import React from 'react';
import './../../styles/dashboard_main/TopNavigationBar.css';
import { FaBell, FaCog, FaUserCircle } from 'react-icons/fa';
import logo from './../../assets/images/platzo_logo.png'
import './../../styles/landing/NavBar.css'; // Link the CSS file
import LogoutButton from './LogoutButton';


const TopNavigationBar = () => {

  // const handleLogout = () => {
  //   // Implement logout functionality here
  //   console.log('Logout clicked');
  //   window.location.href = '/';
  // };

  return (
    <div className="top-nav">
      {/* Left Section: Logo and Website Name */}
      <div className="logo-section">
        <img src={logo} alt="Logo" className="logo" />
        {/* <h1 className="website-name">Loan Management System</h1> */}
      </div>
      
      {/* Right Section: Notifications, Settings, Profile, and Logout */}
      <div className="user-controls">
        <FaBell className="icon" title="Notifications" />
        <FaCog className="icon" title="Settings" />
        <FaUserCircle className="icon profile-icon" title="Profile" />
        <LogoutButton />
      </div>
    </div>
  );
};

export default TopNavigationBar;
