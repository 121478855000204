import React from 'react';
import './../../styles/collection/TransactionDetailsModal.css';
import { FaTimes } from 'react-icons/fa';

const TransactionDetailsModal = ({ transaction, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          <FaTimes />
        </button>
        <h2>Transaction Details</h2>
        <div className="transaction-details">
          <div className="detail-row">
            <span className="detail-label">Payment Transaction ID:</span>
            <span className="detail-value">{transaction.paymentId}</span>
          </div>
          <div className="detail-row">
            <span className="detail-label">Debit UTR:</span>
            <span className="detail-value">{transaction.debitUtr}</span>
          </div>
          <div className="detail-row">
            <span className="detail-label">Final Settlement UTR:</span>
            <span className="detail-value">{transaction.finalSettlementUtr}</span>
          </div>
          {transaction.status.toLowerCase() === 'failure' && (
            <div className="bounce-memo-section">
              <button className="bounce-memo-button">
                Bounce Memo
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionDetailsModal;
