import React, { useState, useEffect } from 'react';
import { Card, Spin, Alert, Select } from 'antd';
import { Column } from '@ant-design/plots';

const CollectionAnalytics = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [collectionData, setCollectionData] = useState([]);
    const [timeFrame, setTimeFrame] = useState('30days');

    const timeFrameOptions = [
        { value: '7days', label: 'Last 7 Days' },
        { value: '30days', label: 'Last 30 Days' },
        { value: '90days', label: 'Last 3 Months' },
        { value: '180days', label: 'Last 6 Months' },
        { value: '365days', label: 'Last Year' },
    ];

    useEffect(() => {
        setLoading(true);
        // TODO: Replace with actual API call
        const mockData = [
            { month: 'Jan', collected: 2800, target: 3000 },
            { month: 'Feb', collected: 2900, target: 3000 },
            { month: 'Mar', collected: 3100, target: 3000 },
            { month: 'Apr', collected: 2950, target: 3000 },
            { month: 'May', collected: 3200, target: 3000 },
            { month: 'Jun', collected: 3300, target: 3000 },
        ];

        // Transform data into the format expected by the Column component
        const transformedData = mockData.reduce((acc, item) => {
            acc.push(
                { month: item.month, type: 'Collected', value: item.collected },
                { month: item.month, type: 'Target', value: item.target }
            );
            return acc;
        }, []);

        setCollectionData(transformedData);
        setLoading(false);
    }, [timeFrame]);

    const config = {
        data: collectionData,
        isGroup: true,
        xField: 'month',
        yField: 'value',
        seriesField: 'type',
        groupField: 'type',
        columnStyle: {
            radius: [4, 4, 0, 0],
        },
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
        color: ['#1890ff', '#f5222d'], // Blue for Collected, Red for Target
        legend: {
            position: 'top',
        },
        aria: {
            enabled: true,
            description: 'A column chart showing collection metrics over time',
        },
    };

    return (
        <div className="collection-analytics" role="region" aria-label="Collection Analytics">
            <Card 
                title="Collection Analytics"
                extra={
                    <Select
                        aria-label="Select time frame"
                        defaultValue="30days"
                        style={{ width: 200 }}
                        onChange={setTimeFrame}
                        options={timeFrameOptions}
                    />
                }
            >
                {error && (
                    <Alert
                        message="Error"
                        description={error}
                        type="error"
                        showIcon
                        style={{ marginBottom: 16 }}
                    />
                )}
                
                {loading ? (
                    <div style={{ textAlign: 'center', padding: '50px' }}>
                        <Spin size="large" />
                    </div>
                ) : (
                    <Column {...config} />
                )}
            </Card>
        </div>
    );
};

export default CollectionAnalytics;
