import React, { useState, useEffect } from 'react';
import { Select, Card, Row, Col, Statistic, Button } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DownloadOutlined } from '@ant-design/icons';

const { Option } = Select;

const ChannelAnalytics = () => {
  const [timeFrame, setTimeFrame] = useState('daily');
  const [data, setData] = useState([]);

  // Mock data - replace with actual API call
  useEffect(() => {
    const mockData = [
      { date: '2024-01-01', web: 52, mobile: 38, api: 10 },
      { date: '2024-01-02', web: 48, mobile: 32, api: 20 },
      { date: '2024-01-03', web: 50, mobile: 35, api: 15 },
      { date: '2024-01-04', web: 60, mobile: 30, api: 10 },
      { date: '2024-01-05', web: 47, mobile: 33, api: 20 },
    ];
    setData(mockData);
  }, [timeFrame]);

  const handleTimeFrameChange = (value) => {
    setTimeFrame(value);
  };

  const handleDownload = () => {
    const csvContent = [
      ['Date', 'Web', 'Mobile', 'API'],
      ...data.map(item => [
        item.date,
        item.web,
        item.mobile,
        item.api
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `channel-analytics-${timeFrame}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="channel-analytics">
      <div className="analytics-header">
        <h2>Channel Performance Analytics</h2>
        <div className="header-controls">
          <Select 
            defaultValue="daily" 
            style={{ width: 120, marginRight: 16 }} 
            onChange={handleTimeFrameChange}
          >
            <Option value="daily">Daily</Option>
            <Option value="weekly">Weekly</Option>
            <Option value="monthly">Monthly</Option>
          </Select>
          <Button 
            type="primary" 
            icon={<DownloadOutlined />} 
            onClick={handleDownload}
          >
            Export Data
          </Button>
        </div>
      </div>

      <Row gutter={16} className="stats-cards">
        <Col span={8}>
          <Card>
            <Statistic
              title="UPI Autopay"
              value={data.length > 0 ? data[data.length - 1].web : 0}
              suffix="%"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="NACH"
              value={data.length > 0 ? data[data.length - 1].mobile : 0}
              suffix="%"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Payment Gateway"
              value={data.length > 0 ? data[data.length - 1].api : 0}
              suffix="%"
            />
          </Card>
        </Col>
      </Row>

      <Card className="chart-card">
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="web" stroke="#8884d8" name="UPI" />
            <Line type="monotone" dataKey="mobile" stroke="#82ca9d" name="NACH" />
            <Line type="monotone" dataKey="api" stroke="#ffc658" name="Payment Gateway" />
          </LineChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
};

export default ChannelAnalytics;
