// src/components/LogoutButton.js
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'; // Hook for navigation
import axios from 'axios';

const LogoutButton = ({ token }) => {
  const history = useHistory(); // Use history hook to redirect

  const handleLogout = async () => {
    try {
      // Call the Django logout API
      await axios.post('http://127.0.0.1:8000/api/accounts/logout/', {
        refresh: localStorage.getItem('refresh_token'), // Send the refresh token to logout
      });

      // Remove tokens from localStorage or memory
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');

      // Redirect to the home page after logout
      history.push('/'); // Navigate to the home page
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return <button className="logout-button" onClick={handleLogout}>Logout</button>;
};

export default LogoutButton;


