import React, { useState } from 'react';
import { Select, Card, Spin, Alert } from 'antd';
import { Line } from '@ant-design/plots';

const DebitAnalytics = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [debitData, setDebitData] = useState([]);
    const [timeFrame, setTimeFrame] = useState('30days');

    const timeFrameOptions = [
        { value: '7days', label: 'Last 7 Days' },
        { value: '30days', label: 'Last 30 Days' },
        { value: '90days', label: 'Last 3 Months' },
        { value: '180days', label: 'Last 6 Months' },
        { value: '365days', label: 'Last Year' },
    ];

    // Dummy data based on timeframe selection
    React.useEffect(() => {
        setLoading(true);
        // Generate dummy data based on timeframe
        const generateDummyData = () => {
            const data = [];
            let days;
            switch(timeFrame) {
                case '7days':
                    days = 7;
                    break;
                case '30days':
                    days = 30;
                    break;
                case '90days':
                    days = 90;
                    break;
                case '180days':
                    days = 180;
                    break;
                case '365days':
                    days = 365;
                    break;
                default:
                    days = 30;
            }

            for (let i = 0; i < days; i++) {
                const date = new Date();
                date.setDate(date.getDate() - i);
                data.push({
                    date: date.toISOString().split('T')[0],
                    amount: Math.floor(Math.random() * 10000) + 5000,
                    type: 'Successful Debits'
                });
                data.push({
                    date: date.toISOString().split('T')[0],
                    amount: Math.floor(Math.random() * 3000) + 1000,
                    type: 'Failed Debits'
                });
            }
            return data.sort((a, b) => new Date(a.date) - new Date(b.date));
        };

        // Simulate API delay
        setTimeout(() => {
            setDebitData(generateDummyData());
            setLoading(false);
        }, 1000);
    }, [timeFrame]);

    const config = {
        data: debitData,
        xField: 'date',
        yField: 'amount',
        seriesField: 'type',
        point: {
            size: 5,
            shape: 'diamond',
        },
        tooltip: {
            showMarkers: true,
        },
        state: {
            active: {
                style: {
                    shadowBlur: 4,
                    stroke: '#000',
                    fill: 'red',
                },
            },
        },
        interactions: [
            {
                type: 'marker-active',
            },
        ],
        aria: {
            enabled: true,
            description: 'A line chart showing debit analytics over time',
        },
    };

    return (
        <div className="debit-analytics" role="region" aria-label="Debit Analytics">
            <Card 
                title="Debit Analytics"
                extra={
                    <Select
                        aria-label="Select time frame"
                        defaultValue="30days"
                        style={{ width: 200 }}
                        onChange={setTimeFrame}
                        options={timeFrameOptions}
                    />
                }
            >
                {error && (
                    <Alert
                        message="Error"
                        description={error}
                        type="error"
                        showIcon
                        style={{ marginBottom: 16 }}
                    />
                )}
                
                {loading ? (
                    <div style={{ textAlign: 'center', padding: '50px' }}>
                        <Spin size="large" />
                    </div>
                ) : (
                    <Line {...config} />
                )}
            </Card>
        </div>
    );
};

export default DebitAnalytics;
