// src/components/Login.js
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'; // For routing
import axios from 'axios'; // For API calls
import './../../styles/login/Login.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory(); // Initialize history for navigation

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Replace with your API endpoint
      const response = await axios.post('http://127.0.0.1:8000/api/accounts/login/', {
        'username' : username, 
        'password' : password
      });

      localStorage.setItem('access', response.data.access);
      localStorage.setItem('refresh', response.data.refresh);

      const data = response.data;

      console.log(data);

      if (response.status === 200) {
        // If the API call succeeded, route to the dashboard and pass user ID
        history.push({
          pathname: "/dashboard",
          state: { token: response.data.access }, // Passing the access token
        }); // Pass user ID to dashboard
      } else {
        // Handle unsuccessful login attempts
        setErrorMessage(data.message || 'Login failed. Please try again.'); // Display error message
      }
    } catch (errorMessage) {
      setErrorMessage('An error occurred. Please try again.'); // Handle network errors
      console.log(errorMessage);
    }
  };


  return (
    <div className="login-container">
      <h2>Login to Your Account</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Username or Email:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter your username or email"
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            required
          />
        </div>
        <button type="submit" className="login-button">Login</button>
        {errorMessage && <div className="error-message">{errorMessage}</div>} {/* Display error message */}
      </form>
      <button className="forgot-password">Forgot Password?</button>
    </div>
  );
};

export default Login;