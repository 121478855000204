import React from 'react';
import './DocumentGrid.css';

const DocumentGrid = () => {
  const documents = [
    { id: 1, name: 'Address Proof', file: 'address_proof.pdf', icon: '📍' },
    { id: 2, name: 'Identity Proof', file: 'identity_proof.pdf', icon: '🪪' },
    { id: 3, name: 'Agreement', file: 'agreement.pdf', icon: '📄' },
    { id: 4, name: 'Sanction Letter', file: 'sanction_letter.pdf', icon: '📝' },
    { id: 5, name: 'Key Facts Statement', file: 'key_facts.pdf', icon: '📋' },
    { id: 6, name: 'Signature Specimen', file: 'signature.pdf', icon: '✍️' },
    { id: 7, name: 'Bank Proof', file: 'bank_proof.pdf', icon: '🏦' },
  ];

  const handleDownload = (file) => {
    // Implement download logic here
    console.log(`Downloading ${file}`);
  };

  const handleView = (file) => {
    // Implement view logic here
    console.log(`Viewing ${file}`);
  };

  return (
    <div className="document-grid-container">
      <div className="document-grid">
        {documents.map((doc) => (
          <div key={doc.id} className="document-card">
            <div className="document-icon">{doc.icon}</div>
            <div className="document-name">{doc.name}</div>
            <div className="document-actions">
              <button 
                className="action-button view-button"
                onClick={() => handleView(doc.file)}
              >
                <span className="button-icon">👁️</span>
                View
              </button>
              <button 
                className="action-button download-button"
                onClick={() => handleDownload(doc.file)}
              >
                <span className="button-icon">⬇️</span>
                Download
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentGrid;
