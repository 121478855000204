import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { FaTimes, FaCloudUploadAlt } from 'react-icons/fa';
import './../../styles/registration/RegistrationPage.css';

const BulkCollectionPage = () => {
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [uploadedData, setUploadedData] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [showLoanIdPrompt, setShowLoanIdPrompt] = useState(false);
  const [loanId, setLoanId] = useState('');
  const [transactionsData, setTransactionsData] = useState(() => {
    const savedTransactionsData = localStorage.getItem('transactionsData');
    return savedTransactionsData ? JSON.parse(savedTransactionsData) : [
    { loanId: "L-001",
      transactions : [
      {
        type: "EDI",
        instalmentNo: 1,
        status: "Success",
        outstandingPrincipal: "₹90,000",
        principal: "₹10,000",
        interest: "₹1,000",
        instalment: "₹11,000",
        statusUpdateTimestamp: "Jun 20, 2023 10:00 AM",
        paymentId: "123456",
        debitUtr: "BANK123",
        finalSettlementUtr: "SBINR520240817123456"
      }]
    }]
  });

  useEffect(() => {
    localStorage.setItem('transactionsData', JSON.stringify(transactionsData));
  }, [transactionsData]);


  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];
    if (uploadedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setUploadedData(jsonData);
        setFile(uploadedFile);
      };
      reader.readAsArrayBuffer(uploadedFile);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFileUpload(e);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const clearFile = () => {
    setFile(null);
    setUploadedData([]);
    setShowLoanIdPrompt(false);
    setLoanId('');
  };

  const handlePreview = () => {
    setShowPreview(true);
  };

  const closePreview = () => {
    setShowPreview(false);
  };

  const handleSubmit = () => {
    setShowLoanIdPrompt(true);
  };

  const handleLoanIdSubmit = () => {
    if (loanId.trim()) {
      // Here you can handle the final submission with both file and loan ID
      console.log('Submitting with loan ID:', loanId, 'and file:', file);
      transactionsData.push({
        loanId: loanId.trim(),
        transactions: uploadedData
      });
      console.log(transactionsData);
      localStorage.setItem('transactionsData', JSON.stringify(transactionsData));
      alert('Submission successful!');
      history.push('/collections');
    } else {
      alert('Please enter a valid Loan ID');
    }
  };

  return (
    <div className="registration-container">
      <div className="header-container">
        <h2>Upload Amortization Schedule</h2>
        <div className="close-icon" onClick={() => history.push('/collections')}>
          <FaTimes size={24} />
        </div>
      </div>

      {/* Drag and Drop Zone */}
      <div 
        className="drag-drop-zone" 
        onDragOver={handleDragOver} 
        onDrop={handleDrop}
        style={{
          cursor: 'pointer',
          position: 'relative',
          textAlign: 'center',
          padding: '40px 20px',
          background: '#f8f9fa',
          border: '2px dashed #c4ccd8',
          borderRadius: '12px',
          transition: 'all 0.3s ease'
        }}
      >
        {file ? (
          <div className="file-info">
            <span className="file-name">{file.name}</span>
            <button 
              className="clear-button" 
              onClick={clearFile}
              style={{
                position: 'absolute',
                right: '15px',
                top: '50%',
                transform: 'translateY(-50%)',
                background: 'none',
                border: 'none',
                color: '#dc3545',
                fontSize: '1.2rem',
                cursor: 'pointer'
              }}
            >
              <FaTimes />
            </button>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px' }}>
            <FaCloudUploadAlt size={48} color="#1d2b64" />
            <p style={{ margin: '0' }}>
              Drag and drop your file here{' '}
              <span style={{ margin: '0 5px' }}>or</span>
              <span 
                className="upload-link" 
                onClick={() => document.getElementById('fileInput').click()}
                style={{
                  color: '#1d2b64',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontWeight: '500'
                }}
              >
                browse
              </span>
              {' '}to upload an XLSX file
            </p>
          </div>
        )}
        <input
          type="file"
          id="fileInput"
          accept=".xlsx, .xls"
          onChange={handleFileUpload}
          style={{ display: 'none' }}
        />
      </div>

      {/* Buttons */}
      <div className="button-group">
        {file && !showLoanIdPrompt && (
          <button 
            className="submit-button" 
            onClick={handleSubmit}
            style={{
              padding: '10px 20px',
              fontSize: '1rem',
              backgroundColor: '#1d2b64',
              color: 'white',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer'
            }}
          >
            Submit
          </button>
        )}
        {file && (
          <button 
            className="submit-button" 
            onClick={handlePreview}
            style={{
              padding: '10px 20px',
              fontSize: '1rem',
              backgroundColor: '#1d2b64',
              color: 'white',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer'
            }}
          >
            Preview
          </button>
        )}
      </div>

      {/* Loan ID Prompt Modal */}
      {showLoanIdPrompt && (
        <div className="modal">
          <div className="modal-content" style={{ width: '400px' }}>
            <button 
              className="close-button" 
              onClick={() => setShowLoanIdPrompt(false)}
              style={{
                position: 'absolute',
                right: '15px',
                top: '15px',
                background: 'none',
                border: 'none',
                fontSize: '1.2rem',
                cursor: 'pointer'
              }}
            >
              <FaTimes />
            </button>
            <h3>Enter Loan ID</h3>
            <div className="loan-id-input">
              <input
                type="text"
                value={loanId}
                onChange={(e) => setLoanId(e.target.value)}
                placeholder="Enter Loan ID"
                className="form-input"
                style={{
                  width: '100%',
                  padding: '8px',
                  marginBottom: '15px',
                  border: '1px solid #c4ccd8',
                  borderRadius: '4px'
                }}
              />
              <button 
                className="submit-button" 
                onClick={handleLoanIdSubmit}
                style={{
                  padding: '8px 16px',
                  fontSize: '1rem',
                  backgroundColor: '#1d2b64',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer'
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Preview Modal */}
      {showPreview && (
        <div className="modal">
          <div className="modal-content" style={{ width: '90%', maxWidth: '1200px', position: 'relative' }}>
            <FaTimes 
              className="close-button" 
              onClick={closePreview}
              style={{
                position: 'absolute',
                right: '20px',
                top: '20px',
                cursor: 'pointer',
                fontSize: '1.5rem',
                color: '#666'
              }}
            />
            <h3>Preview Data</h3>
            <div className="preview-table">
              <table>
                <thead>
                  <tr>
                    {uploadedData.length > 0 &&
                      Object.keys(uploadedData[0]).map((key) => (
                        <th key={key}>{key}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {uploadedData.map((row, index) => (
                    <tr key={index}>
                      {Object.values(row).map((cell, idx) => (
                        <td key={idx}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BulkCollectionPage;
