import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DashboardLayout from './../../components/dashboard_main/DashboardLayout';
import Registration from './../registration';
import Collection from './../collection';
import Dashboard from './../dashboard';
import RegisterMandateView from './../register_mandate_view';
import BulkRegisterPage from './../bulk_register';
import BulkDebitPage from './../bulk_debit';
import Profile from './../profile';
import Analytics from './../analytics';
import CustomerEngagement from './../customer_engagement';

function Dashboard_Main() {
  return (
    <Router>
      <DashboardLayout>
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/registrations" component={Registration} />
          <Route path="/profile" component={Profile} />
          <Route path="/collections" component={Collection} />
          <Route path="/register-mandate" component={RegisterMandateView} />
          <Route path="/bulk-register" component={BulkRegisterPage} />
          <Route path="/bulk-debit" component={BulkDebitPage} />
          <Route path="/analytics" component={Analytics} />
          <Route path="/reports" component={Analytics} />
          <Route path="/customer-engagement" component={CustomerEngagement} />
          {/* Add additional routes as needed */}
        </Switch>
      </DashboardLayout>
    </Router>
  );
}

export default Dashboard_Main;
