import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { useHistory } from 'react-router-dom';
import './../../styles/registration/RegistrationPage.css';

const RegistrationPage = () => {
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [uploadedData, setUploadedData] = useState([]);
  const [showPreview, setShowPreview] = useState(false);

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];
    if (uploadedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setUploadedData(jsonData);
        setFile(uploadedFile);
      };
      reader.readAsArrayBuffer(uploadedFile);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFileUpload(e);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const clearFile = () => {
    setFile(null);
    setUploadedData([]);
  };

  const handlePreview = () => {
    setShowPreview(true);
  };

  const closePreview = () => {
    setShowPreview(false);
  };

  const handleSave = () => {
    // Fetch the saved profiles from localStorage
  let savedProfiles = localStorage.getItem('profiles');

  // Parse the JSON string into an array, if it exists
  savedProfiles = savedProfiles ? JSON.parse(savedProfiles) : [];

  // Append the uploaded data (assuming uploadedData is an array)
  savedProfiles = savedProfiles.concat(uploadedData);

  // Save the updated list back to localStorage
  localStorage.setItem('profiles', JSON.stringify(savedProfiles));

    alert('Submission successful!');
    history.push('/profile');
  }

  const handleRegister = () => {
    alert("Still in progress....");
  }

  return (
    <div className="registration-container">
      <h2>Bulk Registrations</h2>

      {/* Drag and Drop Zone */}
      <div className="drag-drop-zone" onDragOver={handleDragOver} onDrop={handleDrop}>
        {file ? (
          <div className="file-info">
            <span className="file-name">{file.name}</span>
            <button className="clear-button" onClick={clearFile}>&times;</button>
          </div>
        ) : (
          <p>Drag and drop or <span className="upload-link" onClick={() => document.getElementById('fileInput').click()}>browse</span> to upload an XLSX file</p>
        )}
        <input
          type="file"
          id="fileInput"
          accept=".xlsx, .xls"
          onChange={handleFileUpload}
          style={{ display: 'none' }}
        />
      </div>

      {/* Buttons */}
      <div className="button-group">
        <button className="upload-button" onClick={() => document.getElementById('fileUpload').click()}>
            Upload File
          </button>
          <input
            type="file"
            id="fileUpload"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />
        <button disabled={!file} className="register-button" onClick={handleRegister}>Register Users</button>
        <button disabled={!file} className="save-button" onClick={handleSave}>Save Users</button>
        {file && <button className="preview-button" onClick={handlePreview}>Preview</button> }
      </div>

      {/* Preview Modal */}
      {showPreview && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={closePreview}>&times;</button>
            <h3>Preview Data</h3>
            <div className="preview-table">
              <table>
                <thead>
                  <tr>
                    {uploadedData.length > 0 &&
                      Object.keys(uploadedData[0]).map((key) => (
                        <th key={key}>{key}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {uploadedData.map((row, index) => (
                    <tr key={index}>
                      {Object.values(row).map((cell, idx) => (
                        <td key={idx}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {/* Footer */}
      <div className="footer">
        <Link to="/registrations" className="back-button">Back</Link>
      </div>
    </div>
  );
};

export default RegistrationPage;
