import React from 'react';
import './../../styles/dashboard/DashboardView.css';
import OverviewCard from './OverviewCard';

const DashboardView = () => {
  return (
    <div className="dashboard">
      <div className="dash-header">
        <h1>Portfolio Overview</h1>
        <p>Here’s how your portfolio is performing</p>
      </div>
      <div className="overview">
        <OverviewCard
          title="Disbursed Portfolio"
          value="Rs 12,00,00,000"
          change="+5%"
          timeFrame="Last 30 days"
          chartData={[10, 20, 15, 25, 18, 22]}
        />
        <OverviewCard
          title="Last 7 days Collections"
          value="+5%"
          change="-2%"
          timeFrame="Last 7 days"
          chartData={[5, 10, 8, 12, 11]}
        />
        <OverviewCard
          title="Last 7 days total amount bounce"
          value="Rs 3,00,000"
          change="-3%"
          timeFrame="Last 7 days"
          chartData={[5, 7, 6, 8, 6]}
        />
      </div>
      <div className="total-collection">
        <h3>Upcomming Collection Cycle</h3>
        <div className="total-collection-data">
          <div className="total-amount">
            <span>Total Pending Collection:</span>
            <h2>Rs 4,00,00,000</h2>
          </div>
          <div className="scheduled-debit">
            <span>Next Scheduled Debit Date and Time:</span>
            <p>10:00 AM Nov 28, 2024 </p>
          </div>
          <div className="scheduled-time">
            <span>Next Scheduled Debit Amount:</span>
            <p>Rs 30,00,000</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default DashboardView;
