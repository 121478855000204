import React, { useState } from 'react';
import { Tabs } from 'antd';
import DebitAnalytics from './DebitAnalytics';
import ChannelAnalytics from './ChannelAnalytics';
import BounceAnalytics from './BounceAnalytics';
import CustomerAnalytics from './CustomerAnalytics';
import CollectionAnalytics from './CollectionAnalytics';
import '../../styles/analytics/AnalyticsView.css';

const { TabPane } = Tabs;

const AnalyticsView = () => {
    return (
        <div className="analytics-container">
            <h1>Analytics Dashboard</h1>
            
            <Tabs defaultActiveKey="1" className="analytics-tabs">
                <TabPane tab="Debit Analytics" key="1">
                    <DebitAnalytics />
                </TabPane>
                
                <TabPane tab="Channel Performance" key="2">
                    <ChannelAnalytics />
                </TabPane>
                
                <TabPane tab="Bounce Analysis" key="3">
                    <BounceAnalytics />
                </TabPane>
                
                <TabPane tab="Customer Insights" key="4">
                    <CustomerAnalytics />
                </TabPane>
                
                <TabPane tab="Collection Metrics" key="5">
                    <CollectionAnalytics />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default AnalyticsView;
