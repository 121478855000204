import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import logo from './../../assets/images/platzo_logo.png'; // Import your logo image
import './../../styles/landing/NavBar.css'; // Link the CSS file

// Import product icons
import { FaDigitalOcean } from 'react-icons/fa';

const NavBar = () => {
  const [showProducts, setShowProducts] = useState(false);
  const [showUseCases, setShowUseCases] = useState(false); // Separate state for Use Cases

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="Company Logo" />
      </div>
      <ul className="navbar-links-left">
        <li>About us</li> 
        <li
          onMouseEnter={() => setShowProducts(true)}
          onMouseLeave={() => setShowProducts(false)}
          className="products-menu"
        >
          Products
          {showProducts && (
            <div className="products-popup">
              <div className="popup-item">
                <FaDigitalOcean className="popup-icon" />
                <div>
                  <h4>Loan Management Solution (LMS)</h4>
                </div>
              </div>
              <div className="popup-item">
                <FaDigitalOcean className="popup-icon" />
                <div>
                  <h4>Compliance Check & Audit Control (Coming Soon)</h4>
                </div>
              </div>
              <div className="popup-item">
                <FaDigitalOcean className="popup-icon" />
                <div>
                  <h4>AML Solution (Coming Soon)</h4>
                </div>
              </div>
              <div className="popup-item">
                <FaDigitalOcean className="popup-icon" />
                <div>
                  <h4>Collection Trends & Portfolio Monitoring</h4>
                </div>
              </div>
            </div>
          )}
        </li>
        <li
          onMouseEnter={() => setShowUseCases(true)}
          onMouseLeave={() => setShowUseCases(false)}
        >
          Use Cases
          {showUseCases && (
            <div className="products-popup">
              <div className="popup-item">
                <FaDigitalOcean className="popup-icon" />
                <div>
                  <h4>Equated Daily Installment (EDI) Loans</h4>
                </div>
              </div>
              <div className="popup-item">
                <FaDigitalOcean className="popup-icon" />
                <div>
                  <h4>Equated Monthly Installment (EMI) Loans</h4>
                </div>
              </div>
            </div>
          )}
        </li>
      </ul>
      <div className="navbar-right">
        <div className="navbar-book-demo">
          <Link to="/login" > 
            <button className="book-demo-button">Login</button>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
