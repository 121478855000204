// src/components/FeatureStepsSection.js
import React, { useEffect, useRef } from 'react';
import './../../styles/landing/FeatureStepsSection.css';

const FeatureStepsSection = () => {
  const progressRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const section = progressRef.current;
      const sectionTop = section.getBoundingClientRect().top;
      const sectionHeight = section.getBoundingClientRect().height;
      const windowHeight = window.innerHeight;

      // Calculate the scroll progress with a slower animation effect
      const progress = Math.min(
        Math.max((windowHeight - sectionTop) / sectionHeight, 0),
        1
      );

      section.style.setProperty('--progress', progress);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const features = [
    {
      title: "Streamlined Collections Across Channels",
      description: [
        "Manage collections from multiple sources seamlessly",
        "Ensure efficient and hassle-free operations",
        "Improve collections, increase revenue, and enhance borrower engagement"
      ]
    },
    {
      title: "Comprehensive Collection Trends & Portfolio Monitoring",
      description: [
        "Track collection trends in real time",
        "Monitor portfolio performance for data-driven decision-making",
        "Assess individual channel collection efficiency"
      ]
    },
    {
      title: "Risk Assessment for Targeted Collection Strategies",
      description: [
        "Conduct risk assessments based on days past due",
        "Prioritize high-risk cases to ensure recovery likelihood",
        "Use machine learning models to suggest optimal collection strategies"
      ]
    },
    {
      title: "Enhanced Collection through Behavioral Insights & Performance Metrics",
      description: [
        "Gain insights into individual borrower behavior",
        "Analyze individual agent performance",
        "Assess channel performance to optimize strategies and productivity"
      ]
    }
  ];

  const positions = [
    { top: '5%', left: '-440%' },
    { top: '26%', left: '-440%' },
    { top: '48%', left: '-440%' },
    { top: '70%', left: '-440%' },
  ];

  return (
    <section className="feature-steps-section" ref={progressRef}>
      {features.map((feature, index) => (
        <div className={`feature-step ${index % 2 === 0 ? 'left' : 'right'}`} key={index}>
          <div className="feature-content">
            <h3>{feature.title}</h3>
            <ul>
              {feature.description.map((desc, idx) => (
                <li key={idx}>{desc}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}

      {/* <div className="progress-line">
        {features.map((_, index) => (
          <div className="step-number" style={{ top: `${(index + 1) * 25}%` }} key={index}>
            {index + 1}
          </div>
        ))}
      </div> */}

      <div className="progress-line">
        {features.map((_, index) => (
          <div
            className="step-number"
            style={{ top: positions[index].top, left: positions[index].left }}
            key={index}
          >
            {index + 1}
          </div>
        ))}
      </div>

    </section>
  );
};

export default FeatureStepsSection;
