import React, { useState, useEffect } from 'react';
import './../../styles/landing/PopupForm.css'; // Link the CSS file
import axios from 'axios';

const PopupForm = ({ onClose , onSubmit}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    description: ''
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://127.0.0.1:8000/api/contact/', {
        'name' : formData.name, 
        'email': formData.email,
        'mobile_number': formData.number,
        'description': formData.description
      });
      if (response.status === 200) {
        console.log('Form submitted successfully');
        onSubmit();
        onClose();
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Failed to submit form');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className="popup-form">
        <div className="popup-form-content">
          <span className="close-button" onClick={onClose}>&times;</span>
          <form onSubmit={handleSubmit}>
            <label>
              Name :
              <input type="text" name="name" value={formData.name} onChange={handleChange} required />
            </label>
            <label>
              Email :
              <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            </label>
            <label>
              Number :
              <input type="tel" name="number" value={formData.number} onChange={handleChange} required />
            </label>
            <label>
              Description :
              <textarea name="description" value={formData.description} onChange={handleChange} required />
            </label>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default PopupForm;