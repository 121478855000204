import React, { useState, useEffect } from 'react';
import { Card, Spin, Alert, Select } from 'antd';
import { Pie } from '@ant-design/plots';

const CustomerAnalytics = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [customerData, setCustomerData] = useState([]);
    const [timeFrame, setTimeFrame] = useState('30days');

    const timeFrameOptions = [
        { value: '7days', label: 'Last 7 Days' },
        { value: '30days', label: 'Last 30 Days' },
        { value: '90days', label: 'Last 3 Months' },
        { value: '180days', label: 'Last 6 Months' },
        { value: '365days', label: 'Last Year' },
    ];

    useEffect(() => {
        // TODO: Replace with actual API call
        const mockData = [
            { type: '0 Bounce Customers', value: 53 },
            { type: '<5 Customers', value: 36 },
            { type: 'Inactive Customers', value: 4 },
            { type: 'At-Risk Customers', value: 7 },
        ];
        setCustomerData(mockData);
    }, [timeFrame]);

    const config = {
        appendPadding: 10,
        data: customerData,
        angleField: 'value',
        colorField: 'type',
        radius: 0.8,
        label: {
            type: 'outer',
            content: '{name} ({percentage})',
        },
        interactions: [
            {
                type: 'pie-legend-active',
            },
            {
                type: 'element-active',
            },
        ],
        aria: {
            enabled: true,
            description: 'A pie chart showing customer distribution',
        },
    };

    return (
        <div className="customer-analytics" role="region" aria-label="Customer Analytics">
            <Card 
                title="Customer Analytics"
                extra={
                    <Select
                        aria-label="Select time frame"
                        defaultValue="30days"
                        style={{ width: 200 }}
                        onChange={setTimeFrame}
                        options={timeFrameOptions}
                    />
                }
            >
                {error && (
                    <Alert
                        message="Error"
                        description={error}
                        type="error"
                        showIcon
                        style={{ marginBottom: 16 }}
                    />
                )}
                
                {loading ? (
                    <div style={{ textAlign: 'center', padding: '50px' }}>
                        <Spin size="large" />
                    </div>
                ) : (
                    <Pie {...config} />
                )}
            </Card>
        </div>
    );
};

export default CustomerAnalytics;
