import React, { useState } from 'react';
import './../../styles/registration/RegistrationView.css';
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';

const RegistrationView = () => {
  const [active, setActive] = useState('registrations');
  const [registrations, setRegistrations] = useState([
    { customerId: '123456', loanId: 'L-001', status: 'Processing', umrn: 'UMRN123456' },
    { customerId: '234567', loanId: 'L-002', status: 'Pending', umrn: 'NA' },
    { customerId: '345678', loanId: 'L-003', status: 'Failed', umrn: 'NA' },
    // { customerId: '456789', loanId: 'L-004', status: 'Registered', umrn: 'UMRN456789' },
    { customerId: '567890', loanId: 'L-005', status: 'Processing', umrn: 'NA' },
    { customerId: '678901', loanId: 'L-006', status: 'Failed', umrn: 'NA' },
    { customerId: '789012', loanId: 'L-007', status: 'Pending', umrn: 'UMRN789012' },
    { customerId: '890123', loanId: 'L-008', status: 'Pending', umrn: 'NA' },
    { customerId: '901234', loanId: 'L-009', status: 'Processing', umrn: 'NA' },
    { customerId: '012345', loanId: 'L-010', status: 'Failed', umrn: 'UMRN012345' }
  ]);

  const transactions = [
    {
      instalmentNo: 1,
      status: "Success",
      outstandingPrincipal: "₹90,000",
      principal: "₹10,000",
      interest: "₹1,000",
      instalment: "₹11,000",
      statusUpdateTimestamp: "Nov 1, 2023 10:00 AM",
      paymentTransactionId: "TXN0001",
      debitUtr: "UTR001"
    },
    {
      instalmentNo: 2,
      status: "Success",
      outstandingPrincipal: "₹80,000",
      principal: "₹10,000",
      interest: "₹1,000",
      instalment: "₹11,000",
      statusUpdateTimestamp: "Nov 10, 2023 12:30 PM",
      paymentTransactionId: "TXN0002",
      debitUtr: "UTR002"
    },
    {
      instalmentNo: 3,
      status: "Failure",
      outstandingPrincipal: "₹80,000",
      principal: "₹10,000",
      interest: "₹1,000",
      instalment: "₹11,000",
      statusUpdateTimestamp: "Nov 20, 2023 3:15 PM",
      paymentTransactionId: "TXN0003",
      debitUtr: "UTR003"
    },
    {
      instalmentNo: 4,
      status: "Pending",
      outstandingPrincipal: "₹80,000",
      principal: "₹10,000",
      interest: "₹1,000",
      instalment: "₹11,000",
      statusUpdateTimestamp: "Nov 25, 2023 5:45 PM",
      paymentTransactionId: "TXN0004",
      debitUtr: "UTR004"
    },
    {
      instalmentNo: 5,
      status: "Success",
      outstandingPrincipal: "₹70,000",
      principal: "₹10,000",
      interest: "₹900",
      instalment: "₹10,900",
      statusUpdateTimestamp: "Dec 1, 2023 10:00 AM",
      paymentTransactionId: "TXN0005",
      debitUtr: "UTR005"
    },
    {
      instalmentNo: 6,
      status: "Success",
      outstandingPrincipal: "₹60,000",
      principal: "₹10,000",
      interest: "₹900",
      instalment: "₹10,900",
      statusUpdateTimestamp: "Dec 10, 2023 1:00 PM",
      paymentTransactionId: "TXN0006",
      debitUtr: "UTR006"
    },
    {
      instalmentNo: 7,
      status: "Failure",
      outstandingPrincipal: "₹60,000",
      principal: "₹10,000",
      interest: "₹900",
      instalment: "₹10,900",
      statusUpdateTimestamp: "Dec 20, 2023 11:45 AM",
      paymentTransactionId: "TXN0007",
      debitUtr: "UTR007"
    },
    {
      instalmentNo: 8,
      status: "Pending",
      outstandingPrincipal: "₹60,000",
      principal: "₹10,000",
      interest: "₹900",
      instalment: "₹10,900",
      statusUpdateTimestamp: "Dec 25, 2023 4:00 PM",
      paymentTransactionId: "TXN0008",
      debitUtr: "UTR008"
    },
    {
      instalmentNo: 9,
      status: "Success",
      outstandingPrincipal: "₹50,000",
      principal: "₹10,000",
      interest: "₹800",
      instalment: "₹10,800",
      statusUpdateTimestamp: "Jan 1, 2024 9:30 AM",
      paymentTransactionId: "TXN0009",
      debitUtr: "UTR009"
    },
    {
      instalmentNo: 10,
      status: "Success",
      outstandingPrincipal: "₹40,000",
      principal: "₹10,000",
      interest: "₹800",
      instalment: "₹10,800",
      statusUpdateTimestamp: "Jan 10, 2024 2:15 PM",
      paymentTransactionId: "TXN0010",
      debitUtr: "UTR010"
    }
  ];
  
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedRegistration, setSelectedRegistration] = useState(null); // State for selected registration
  const recordsPerPage = 4;

  // State for filter
  const [filterStatus, setFilterStatus] = useState({
    All: true,
    // Registered: true,
    Pending: true,
    Failed: true,
    Processing: true,
  });

  const filteredRegistrations = registrations.filter(registration =>
    filterStatus.All || filterStatus[registration.status]
  );

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRegistrations = filteredRegistrations.slice(indexOfFirstRecord, indexOfLastRecord);

  const totalPages = Math.ceil(filteredRegistrations.length / recordsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleCancel = () => {
    const updatedRegistrations = [...registrations];
    updatedRegistrations[selectedIndex].status = 'Cancelled';
    setRegistrations(updatedRegistrations);
    setShowModal(false);
  };

  const openCancelModal = (index) => {
    setSelectedIndex(index);
    setShowModal(true);
  };

  const handleResend = (index) => {
    console.log(`Resend initiated for Customer ID: ${registrations[index].customerId}`);
  };

  const handleFilterChange = (status) => {
    if (status === 'All') {
      const newStatus = !filterStatus.All;
      setFilterStatus({
        All: newStatus,
        // Registered: newStatus,
        Pending: newStatus,
        Failed: newStatus,
        Processing: newStatus,
      });
    } else {
      const updatedFilterStatus = {
        ...filterStatus,
        [status]: !filterStatus[status],
      };
      const allSelected = Object.keys(updatedFilterStatus)
        .filter(key => key !== 'All')
        .every(key => updatedFilterStatus[key]);
      
      updatedFilterStatus.All = allSelected;
      setFilterStatus(updatedFilterStatus);
    }
    setCurrentPage(1);
  };

  const handleViewRegistration = (registration) => {
    setSelectedRegistration(registration); // Set selected registration for viewing
  };

  const handleCloseDetails = () => {
    setSelectedRegistration(null); // Clear selected registration
  };

  return (
    <div className="main-content">
      <div className="header">
        <h2>Registrations</h2>
        <div className="header-buttons">
          <Link
            to="/register-mandate"
            className="register-button no-underline"
            onClick={() => setActive('register-mandate')}
          >
            Register New Mandate
          </Link>
          <Link
            to="/bulk-register"
            className="register-button no-underline"
            onClick={() => setActive('bulk-register')}
          >
            Bulk Register
          </Link>
        </div>
      </div>

      {/* Filter Options */}
      {selectedRegistration ? (
        // Registration Details View
        <div className="loan-details-container">
          <button className="close-button" onClick={handleCloseDetails}>✖</button>
          <h2>View Registration Details</h2>
          <div className="loan-details">
            <div className="loan-info">
              <p><strong>Customer ID:</strong> {selectedRegistration.customerId}</p>
              <p><strong>Loan ID:</strong> {selectedRegistration.loanId}</p>
              <p><strong>Status:</strong> {selectedRegistration.status}</p>
              <p><strong>UMRN:</strong> {selectedRegistration.umrn}</p>
            </div>
            {/* Replace this with the contents of the hooks */}
            <h3>Registration Transactions</h3>
            <table className="repayment-table">
              <thead>
                <tr>
                  <th>Type Of Debit</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Payment Transaction ID</th>
                  <th>Debit UTR</th>
                  <th>Status Update Time Stamp</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((txn, index) => (
                  <tr key={index}>
                    <td>{txn.type}</td>
                    <td>
                      <span className={`status-badge ${txn.status.toLowerCase()}`}>{txn.status}</span>
                    </td>
                    <td>{txn.amount}</td>
                    <td>{txn.paymentId}</td>
                    <td>{txn.debitUtr}</td>
                    <td>{txn.timestamp}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        // Filter and Table View
        <>
      {/* <div className="filter-options">
        <label>
          <input
            type="checkbox"
            checked={filterStatus.All}
            onChange={() => handleFilterChange('All')}
          />
          All
        </label>
        {['Registered', 'Pending', 'Failed', 'Processing'].map((status) => (
          <label key={status}>
            <input
              type="checkbox"
              checked={filterStatus[status]}
              onChange={() => handleFilterChange(status)}
            />
            {status}
          </label>
        ))}
      </div> */}

<div className="filter-options">
  <label className="filter-checkbox">
    <input
      type="checkbox"
      checked={filterStatus.All}
      onChange={() => handleFilterChange('All')}
    />
    <span>All</span>
  </label>
  {/* {['Registered', 'Pending', 'Failed', 'Processing'].map((status) => ( */}
  {['Pending', 'Failed', 'Processing'].map((status) => (
    <label key={status} className="filter-checkbox">
      <input
        type="checkbox"
        checked={filterStatus[status]}
        onChange={() => handleFilterChange(status)}
      />
      <span>{status}</span>
    </label>
  ))}
</div>



      <table className="registration-table">
        <thead>
          <tr>
            <th>Customer ID</th>
            <th>Loan ID</th>
            <th>Mandate Registration Status</th>
            <th>UMRN</th>
            <th>View Details</th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {currentRegistrations.map((registration, index) => (
            <tr key={index}>
              <td>{registration.customerId}</td>
              <td>{registration.loanId}</td>
              <td>
                <span className={`status ${registration.status.toLowerCase()}`}>
                  {registration.status}
                </span>
              </td>
              <td>{registration.umrn}</td>
              <td>
                <button 
                  className="view-link"
                  onClick={() => handleViewRegistration(registration)}
                >
                  <FaEye className="view-icon" /> View
                </button>
              </td>
              {/* <td>
                {registration.status === 'Processing' ? (
                  <button
                    className="resend-button"
                    onClick={() => handleResend(index)}
                  >
                    Resend
                  </button>
                ) : (
                  <button
                    className="cancel-button"
                    onClick={() => openCancelModal(index)}
                    disabled={registration.status === 'Cancelled' || registration.status === 'Failed'}
                  >
                    Cancel
                  </button>
                )}
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination-controls">
        <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
        <span>Page <strong>{currentPage}</strong> of {totalPages}</span>
        <button onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
      </div>
      </>
      )}
    
      {/* Confirmation Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Confirm Cancellation</h3>
            <p>Are you sure you want to cancel the registration?</p>
            <div className="modal-buttons">
              <button className="confirm-button" onClick={handleCancel}>Yes, Cancel</button>
              <button className="cancel-modal-button" onClick={() => setShowModal(false)}>No, Go Back</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegistrationView;
